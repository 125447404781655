import type { AgregatedSignatureRequest, Signature } from '~/types/models/signature';
import type { FiltersValues } from '~/types/TableTypes';
import type { ApiClient, ApiResults, GenericApi } from '~~/composables/useApiFactory';

const entityName = 'signatures';

const populate = {
  document: true,
};

const searchFields = [''];

type Api = GenericApi<Signature> & {
  list: (params?: FlatQueryParams) => Promise<ApiResults<AgregatedSignatureRequest>>;
  getFilters: () => Promise<FiltersValues>;
};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  theApi.list = async (
    params: FlatQueryParams = {},
  ): Promise<ApiResults<AgregatedSignatureRequest>> => {
    try {
      const queryParams = useStrapiQueryParams(params);

      const req = await api<ApiResults<AgregatedSignatureRequest>>(
        `/${entityName}/list?${useQs(queryParams)}`,
        {
          method: 'GET',
        },
      );

      return req;
    } catch (error) {
      console.error(error);
      return { data: [] };
    }
  };

  theApi.getFilters = async () => {
    let res;
    try {
      res = await api(`${entityName}/filters`, {});
    } catch (error) {
      res = null;
    }
    return res as FiltersValues;
  };

  return theApi;
};
