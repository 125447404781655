import { useAuthStore } from '~~/stores/auth';
import agences from '~~/api/agences';
import articles from '~~/api/articles';
import auth from '~~/api/auth';
import clients from '~~/api/clients';
import projets from '~~/api/projets';
import avancements from '~~/api/avancements';
import statuts from '~~/api/statuts';
import lots from '~~/api/lots';
import sectionsNotices from '~~/api/sectionsNotices';
import gammes from '~~/api/gammes';
import typesArticles from '~~/api/typesArticles';
import metres from '~~/api/metres';
import metreLignes from '~~/api/metreLignes';
import metreOptions from '~~/api/metreOptions';
import parametres from '~~/api/parametres';
import informationsMarque from '~~/api/informationsMarque';
import upload from '~~/api/upload';
import avps from '~~/api/avps';
import metreModeles from '~~/api/metreModeles';
import constructionGammes from '~~/api/constructionGammes';
import constructionModeles from '~~/api/constructionModeles';
import constructionVersions from '~~/api/constructionVersions';
import documents from '~~/api/documents';
import contrats from '~~/api/contrats';
import contratAvenants from '~~/api/contratAvenants';
import permis from '~~/api/permis';
import financements from '~~/api/financements';
import terrainsProjets from '~~/api/terrainsProjets';
import ccis from '~/api/ccis';
import epes from '~/api/epes';
import garants from '~/api/garants';
import garanties from '~/api/garanties';
import users from '~/api/users';
import metresTravaux from '~/api/metresTravaux';
import lignesPrecommande from '~/api/lignesPrecommande';
import precommandes from '~/api/precommandes';
import ssttfours from '~/api/ssttfours';
import secteurs from '~/api/secteurs';
import terrains from '~/api/terrains';
import communes from '~/api/communes';
import adresses from '~/api/adresses';
import annonces from '~/api/annonces';
import prepaschantiers from '~/api/prepaschantiers';
import workspaces from '~/api/workspaces';
import gpt from '~/api/gpt';
import commandes from '~/api/commandes';
import commandeLignes from '~/api/commandeLignes';
import chantiers from '~/api/chantiers';
import echeanciers from '~/api/echeanciers';
import userWorkspaceRole from '~/api/userWorkspaceRole';
import factures from '~/api/factures';
import factureDomaines from '~/api/factureDomaines';
import factureAffectations from '~/api/factureAffectations';
import factureCategorie from '~/api/factureCategorie';
import factureReglements from '~/api/factureReglements';
import factureValidations from '~/api/factureValidations';
import typeDocument from '~/api/typeDocument';
import ubiflow from '~/api/ubiflow';
import signatureProvider from '~/api/signatureProvider';
import signatureRequest from '~/api/signatureRequest';
import savs from '~/api/savs';
import signatures from '~/api/signatures';

export const useApi = () => {
  const withWorkspaceStrapiClient = () => {
    const strapiApi = useStrapiClient();
    return (url, fetchOptions) => {
      const options = { ...(fetchOptions || {}) };
      options.headers = { ...(options.headers || {}), workspace: useAuthStore().workspace?.id };
      return strapiApi(url, options);
    };
  };

  const strapiClient = withWorkspaceStrapiClient();
  const strapiAuth = useStrapiAuth();

  return {
    workspaces: workspaces(strapiClient),
    auth: auth(strapiAuth),
    users: users(strapiClient),
    parametres: parametres(strapiClient),
    informationsMarque: informationsMarque(strapiClient),
    clients: clients(strapiClient),
    articles: articles(strapiClient),
    agences: agences(strapiClient),
    projets: projets(strapiClient),
    avancements: avancements(strapiClient),
    statuts: statuts(strapiClient),
    lots: lots(strapiClient),
    sectionsNotices: sectionsNotices(strapiClient),
    gammes: gammes(strapiClient),
    typesArticles: typesArticles(strapiClient),
    metres: metres(strapiClient),
    metresLignes: metreLignes(strapiClient),
    metresOptions: metreOptions(strapiClient),
    upload: upload(strapiClient),
    documents: documents(strapiClient),
    avps: avps(strapiClient),
    metreModeles: metreModeles(strapiClient),
    constructionGammes: constructionGammes(strapiClient),
    constructionModeles: constructionModeles(strapiClient),
    constructionVersions: constructionVersions(strapiClient),
    contrats: contrats(strapiClient),
    contratAvenants: contratAvenants(strapiClient),
    permis: permis(strapiClient),
    financements: financements(strapiClient),
    terrainsProjets: terrainsProjets(strapiClient),
    ccis: ccis(strapiClient),
    epes: epes(strapiClient),
    garanties: garanties(strapiClient),
    prepaschantiers: prepaschantiers(strapiClient),
    garants: garants(strapiClient),
    metresTravaux: metresTravaux(strapiClient),
    lignesPrecommande: lignesPrecommande(strapiClient),
    precommandes: precommandes(strapiClient),
    ssttfours: ssttfours(strapiClient),
    secteurs: secteurs(strapiClient),
    terrains: terrains(strapiClient),
    communes: communes(),
    adresses: adresses(),
    annonces: annonces(strapiClient),
    gpt: gpt(strapiClient),
    commandes: commandes(strapiClient),
    commandesLignes: commandeLignes(strapiClient),
    chantiers: chantiers(strapiClient),
    echeanciers: echeanciers(strapiClient),
    userWorkspaceRoles: userWorkspaceRole(strapiClient),
    factures: factures(strapiClient),
    factureDomaines: factureDomaines(strapiClient),
    factureAffectations: factureAffectations(strapiClient),
    factureCategories: factureCategorie(strapiClient),
    factureReglements: factureReglements(strapiClient),
    factureValidations: factureValidations(strapiClient),
    typesDocument: typeDocument(strapiClient),
    ubiflow: ubiflow(strapiClient),
    signatureProvider: signatureProvider(strapiClient),
    signatures: signatures(strapiClient),
    signatureRequest: signatureRequest(strapiClient),
    savs: savs(strapiClient),
  };
};
