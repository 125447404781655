import { useQs } from '~~/composables/useQs';

export type ApiClient = <T>(url: string, fetchOptions: object) => Promise<T>;

export type FlatQueryParams = {
  page?: number;
  sortField?: string | string[];
  query?: string;
  limit?: number;
  filters?: object;
  populate?: object;
  searchFields?: string[];
};

export type QueryParam = {
  populate: object;
  filters: object;
  sort: string[];
  pagination: {
    page?: number;
    pageSize?: number;
    limit?: number;
  };
};

export type ApiResultDetails = {
  meta?: {
    pagination: {
      total: number;
      page: number;
      pageSize: number;
      pageCount: number;
    };
  };
  error?: {
    status: string; // HTTP status
    name: string; // Strapi error name ('ApplicationError' or 'ValidationError')
    message: string; // A human readable error message
    details: object;
  };
};

export type ApiResults<T> = {
  data: T[];
} & ApiResultDetails;

export type ApiResult<T> = {
  data: T;
} & ApiResultDetails;

const genericFind = async <T>(
  page = 1,
  sortField: string | string[] = ['createdAt:desc'],
  query = '',
  limit = 100,
  filters: object = {},
  populate: object,
  api: ApiClient,
  entityName: string,
  searchFields: string[],
) => {
  const { $loading } = useNuxtApp();
  let res: ApiResults<T> | null;
  try {
    const params = useStrapiQueryParams({
      page,
      sortField,
      query,
      limit,
      filters,
      populate,
      searchFields,
    });
    $loading.value = true;
    res = await api(`${entityName}?${useQs(params)}`, {});
  } catch (error) {
    console.error(error);
    res = null;
  }
  $loading.value = false;
  return res;
};

export type WithId = { id: number | string };

export type NullableOptionals<T> = {
  [K in keyof T]: undefined extends T[K] ? T[K] | null : T[K];
};
export type Updatable<T extends WithId> = Pick<NullableOptionals<T>, 'id'> &
  Partial<Omit<NullableOptionals<T>, 'id'>>;
export type Creatable<T> = Omit<NullableOptionals<T>, 'id'>;

export type GenericApi<T extends WithId> = {
  findOne: (id: number, customPopulate?: object) => Promise<ApiResult<T> | null>;
  find: (
    page?: number,
    sortField?: string | string[],
    query?: string,
    limit?: number,
    filters?: object,
    customPopulate?: object,
  ) => Promise<ApiResults<T> | null>;
  search: (query: string, limit?: number, populate?: object) => Promise<ApiResults<T> | null>;
  create: (entity: Creatable<T>) => Promise<ApiResult<T> | null>;
  update: (entity: Updatable<T>, populate?: object) => Promise<ApiResult<T> | null>;
  delete: (entity: Updatable<T>) => Promise<ApiResult<T> | null>;
};

export const useApiFactory = <T extends WithId>(
  api: ApiClient,
  entityName: string,
  searchFields: string[],
  defaultPopulate: object,
  defaultSortField?: string | string[],
): GenericApi<T> => ({
  findOne: async (id: number, customPopulate?: object) => {
    const { $loading } = useNuxtApp();
    let res: ApiResult<T> | null;
    try {
      const params = { populate: customPopulate || defaultPopulate };
      $loading.value = true;
      res = await api(`${entityName}/${id}?${useQs(params)}`, {});
    } catch (error) {
      console.error(error);
      res = null;
    }
    $loading.value = false;
    return res;
  },

  find: async (
    page?: number,
    sortField?: string | string[],
    query?: string,
    limit?: number,
    filters?: object,
    customPopulate?: object,
  ) => {
    const usedPopulate = customPopulate || defaultPopulate;
    const sortFields = sortField?.length ? sortField : defaultSortField || ['createdAt:desc'];

    return await genericFind<T>(
      page,
      sortFields,
      query,
      limit,
      filters,
      usedPopulate,
      api,
      entityName,
      searchFields,
    );
  },

  search: async (query: string, limit?: number, populate?: object) =>
    await genericFind<T>(
      1,
      '',
      query,
      limit || 5,
      {},
      populate || defaultPopulate,
      api,
      entityName,
      searchFields,
    ),

  create: async (entity: Creatable<T>) => {
    const { $loading } = useNuxtApp();
    let res: ApiResult<T> | null;
    // @ts-expect-error force delete id
    delete entity.id;
    const params = { populate: defaultPopulate };
    try {
      $loading.value = true;
      res = await api(`${entityName}?${useQs(params)}`, {
        method: 'POST',
        body: { data: entity },
      });
    } catch (error) {
      console.error(error);
      res = null;
    }
    $loading.value = false;
    return res;
  },

  update: async (entity: Updatable<T>, populate?: object) => {
    if (!entity.id) return null;
    const { $loading } = useNuxtApp();
    let res: ApiResult<T> | null;
    const params = { populate: populate || defaultPopulate };
    try {
      $loading.value = true;
      res = await api(`${entityName}/${entity.id}?${useQs(params)}`, {
        method: 'PUT',
        body: { data: entity },
      });
    } catch (error) {
      console.error(error);
      res = null;
    }
    $loading.value = false;
    return res;
  },

  delete: async (entity: Updatable<T>) => {
    if (!entity.id) return null;
    const { $loading } = useNuxtApp();
    let res: ApiResult<T> | null;
    try {
      $loading.value = true;
      res = await api(`${entityName}/${entity.id}`, {
        method: 'DELETE',
      });
    } catch (error) {
      console.error(error);
      res = null;
    }
    $loading.value = false;
    return res;
  },
});
